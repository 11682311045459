<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-3">
      <v-card-title class="justify-center">
        <span class="headline">{{ $t("new_academic_education") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="trainingArea.text.en"
                :readonly="show"
                :label="$t('english')"
                outlined
                type="text"
                dense
                required
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="trainingArea.text.pt"
                :readonly="show"
                :label="$t('portuguese')"
                outlined
                type="text"
                dense
                required
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn :color="'primary'" text class="mx-2" @click="$emit('close')">{{
              show ? $t("close") : $t("cancel")
            }}</v-btn>
            <v-btn
              v-if="!show"
              :disabled="isLoading"
              color="primary"
              @click="trainingArea.id ? update() : save()"
              >{{ $t("save") }}</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { formatError } from "@/utils";
import {
  ADD_TRAINING_AREA_MUTATION,
  UPDATE_TRAINING_AREA_MUTATION,
} from "../graphql/Mutation";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";

export default {
  name: "AddTrainingAreaDialog",
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  props: {
    dialog: Boolean,
    trainingArea: Object,
    show: Boolean,
  },
  data: () => ({
    error: undefined,
    isLoading: false,
    showError: false,
    showSuccess: false,
    success: undefined,
    valid: true,
  }),
  computed: {
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
  },
  methods: {
    ...mapActions({
      setTrainingArea: "library/setTrainingArea",
      updateTrainingArea: "library/updateTrainingArea",
    }),
    async save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          const { data } = await this.$apollo.mutate({
            mutation: ADD_TRAINING_AREA_MUTATION,
            variables: {
              trainingAreaInput: this.trainingArea,
            },
          });
          this.setTrainingArea(data.addTrainingArea);
          this.success = this.$t("added_academic_education_successfully", {
            entity: this.$t("academic_education"),
          });
          this.showSuccess = true;
          this.$emit("close");
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
    async update() {
      this.isLoading = true;
      const { id } = this.trainingArea;
      delete this.trainingArea.id;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_TRAINING_AREA_MUTATION,
          variables: {
            id,
            trainingAreaInput: this.trainingArea,
          },
        });
        this.updateTrainingArea(data.updateTrainingArea);
        this.success = this.$t("updated_academic_education_successfully", {
          entity: this.$t("academic_education"),
        });
        this.showSuccess = true;
        this.$emit("close");
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
