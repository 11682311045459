import gql from 'graphql-tag'

export default gql`{
  id
  about
  address
  areasExpertise
  email
  license
  logs
  logo
  media {
    facebook
    linkedin
    twitter
    website
  }
  name
  nuit
  status
  views
  telephone
  telephone2
  category
}
`